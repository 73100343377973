body,
h1 {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
}

h1 {
    font-weight: 900;
    width: 100%;
}

.c-coming-soon {
    text-align: center;
    width: 100%;
    margin: auto;
    color: #444444;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}